import React from "react";
import { Link, Button } from "@chakra-ui/react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link as ReachLink } from "gatsby";

const ButtonSecondary = ({ children, linkType, linkUrl }) => {
  return (
    <>
      {linkType === "Anchor" ? (
        <Link
          ml={[0, 4]}
          mt={[4, 0]}
          as={AnchorLink}
          href={linkUrl}
          d="flex"
          alignItems="center"
          textColor="brand.700"
          fontWeight="600"
          _hover={{ textDecoration: "none" }}
          w={["full", "11.25rem"]}
          _focus={{ outline: "none" }}
        >
          <Button
            bgColor="transparent"
            borderWidth="0.125rem"
            textColor="brand.400"
            borderColor="brand.400"
            borderRadius="0.875rem"
            w={["full", "11.25rem"]}
            h={[10, "2.75rem"]}
            fontSize={["md", "lg"]}
            fontWeight={[600]}
            _hover={{ textColor: "brand.450", borderColor: "brand.450" }}
            _active={{ textColor: "brand.450", borderColor: "brand.450" }}
          >
            {children}
          </Button>
        </Link>
      ) : linkType === "Internal" ? (
        <Link
          ml={[0, 4]}
          mt={[4, 0]}
          as={ReachLink}
          to={linkUrl}
          d="flex"
          alignItems="center"
          textColor="brand.700"
          fontWeight="600"
          _hover={{ textDecoration: "none" }}
          w={["full", "11.25rem"]}
          _focus={{ outline: "none" }}
        >
          <Button
            bgColor="transparent"
            borderWidth="0.125rem"
            textColor="brand.400"
            borderColor="brand.400"
            borderRadius="0.875rem"
            w={["full", "11.25rem"]}
            h={[10, "2.75rem"]}
            fontSize={["md", "lg"]}
            fontWeight={[600]}
            _hover={{ textColor: "brand.450", borderColor: "brand.450" }}
            _active={{ textColor: "brand.450", borderColor: "brand.450" }}
          >
            {children}
          </Button>
        </Link>
      ) : (
        <Link
          ml={[0, 4]}
          mt={[4, 0]}
          href={linkUrl}
          isExternal
          d="flex"
          alignItems="center"
          textColor="brand.700"
          fontWeight="600"
          _hover={{ textDecoration: "none" }}
          w={["full", "11.25rem"]}
          _focus={{ outline: "none" }}
        >
          <Button
            bgColor="transparent"
            borderWidth="0.125rem"
            textColor="brand.400"
            borderColor="brand.400"
            borderRadius="0.875rem"
            w={["full", "11.25rem"]}
            h={[10, "2.75rem"]}
            fontSize={["md", "lg"]}
            fontWeight={[600]}
            _hover={{ textColor: "brand.450", borderColor: "brand.450" }}
            _active={{ textColor: "brand.450", borderColor: "brand.450" }}
          >
            {children}
          </Button>
        </Link>
      )}
    </>
  );
};

export default ButtonSecondary;
