import React from "react";
import { Container, Flex, Image, Center, Link } from "@chakra-ui/react";
import styled from "@emotion/styled";
import SectionHeading from "./SectionHeading";
import { theme } from "../../styles/theme";

const Contact = React.forwardRef(({ data }, ref) => {
  return (
    <Container
      ref={ref}
      id="contact"
      maxW="full"
      centerContent
      py={[12, 14, 20]}
    >
      <Flex maxW="6xl" w="full" justifyContent="center" flexWrap="wrap">
        <Flex
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          order={[0, 0, 1]}
          flexWrap="wrap"
          alignItems="center"
          alignContent="center"
          pb={[8, 8, 0]}
        >
          <SectionHeading textAlign={["left"]}>{data.title}</SectionHeading>
          <TextContainer
            dangerouslySetInnerHTML={{
              __html: data.text.childrenMarkdownRemark[0].html,
            }}
          />
          <Link
            href={`mailto:${data.eMail}`}
            isExternal
            textColor="brand.100"
            fontSize={["sm", "md"]}
            fontWeight="500"
          >
            {data.eMail}
          </Link>
        </Flex>
        <Center
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          px={[8, 8, 8, 0]}
          order={[1, 1, 0]}
        >
          <Image
            src={data.image.file.url}
            maxW="26.5rem"
            w="full"
            h="auto"
            alt={data.title}
          />
        </Center>
      </Flex>
    </Container>
  );
});

const TextContainer = styled.div`
  & p {
    padding-bottom: 1em;
    font-weight: 300;
    color: ${theme.colors.brand[100]};
    font-size: ${theme.fontSizes.sm};
    text-align: left;
    white-space: pre-line;

    @media (min-width: 30rem) {
      font-size: ${theme.fontSizes.md};
    }
  }
`;

export default Contact;
