import React from "react";
import { Button, Link } from "@chakra-ui/react";

const ButtonBig = ({ url, title }) => {
  return (
    <Link
      href={url}
      _hover={{ textDecoration: "none" }}
      w={["full", 60]}
      _focus={{ outline: "none" }}
    >
      <Button
        bgColor="brand.400"
        borderRadius="xl"
        w={["full", 60]}
        h={[10, 12]}
        color="brand.700"
        fontSize={["md", "xl"]}
        fontWeight={[600, 700]}
        boxShadow="lg"
        _hover={{ bgColor: "brand.450" }}
        _active={{ bgColor: "brand.450" }}
      >
        {title}
      </Button>
    </Link>
  );
};

export default ButtonBig;
