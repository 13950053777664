import React from "react";
import { Container, Flex, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import ButtonBig from "./ButtonBig";
import { theme } from "../../styles/theme";

const Cta = ({ data }) => {
  return (
    <Container maxW="full" centerContent py={[10]} bgColor="brand.600">
      <Flex
        maxW="6xl"
        w="full"
        justifyContent={["center", "center", "space-between"]}
        flexWrap={["wrap", "wrap", "nowrap"]}
        alignItems="center"
      >
        <Box
          textColor="brand.100"
          fontSize={["2xl", "3xl"]}
          fontWeight="700"
          flex={["1 0 100%", "1 0 100%", "0 0 calc(100% / 3 * 2)", "0 0 50%"]}
          textAlign={["center", "center", "left"]}
          pb={[6, 6, 0]}
        >
          <TextContainer
            dangerouslySetInnerHTML={{
              __html: data.title.childMarkdownRemark.html,
            }}
          />
        </Box>
        <ButtonBig url={data.linkUrl} title={data.linkText} />
      </Flex>
    </Container>
  );
};

const TextContainer = styled.div`
  & em {
    font-style: normal;
    color: ${theme.colors.brand[400]};
  }
`;

export default Cta;
