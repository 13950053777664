import React from "react";
import { Link, Box } from "@chakra-ui/react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link as ReachLink } from "gatsby";

const TextLinkWithArrow = ({ children, linkType, linkUrl }) => {
  return (
    <>
      {linkType === "Anchor" ? (
        <Link
          as={AnchorLink}
          href={linkUrl}
          d="flex"
          alignItems="center"
          textColor="brand.400"
          fontWeight="600"
          fontSize={["sm", "sm", "md"]}
        >
          {children}
          <ArrowIcon />
        </Link>
      ) : linkType === "Internal" ? (
        <Link
          as={ReachLink}
          to={linkUrl}
          d="flex"
          alignItems="center"
          textColor="brand.400"
          fontWeight="600"
          fontSize={["sm", "sm", "md"]}
        >
          {children}
          <ArrowIcon />
        </Link>
      ) : (
        <Link
          href={linkUrl}
          isExternal
          d="flex"
          alignItems="center"
          textColor="brand.400"
          fontWeight="600"
          fontSize={["sm", "sm", "md"]}
        >
          {children}
          <ArrowIcon />
        </Link>
      )}
    </>
  );
};

export default TextLinkWithArrow;

const ArrowIcon = () => {
  return (
    <Box ml={[1, 2]}>
      <svg
        width="7"
        height="13"
        viewBox="0 0 7 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: "0.4375rem", height: "auto" }}
      >
        <path
          d="M1.2257 0.428606C1.31031 0.357606 1.43646 0.368643 1.50746 0.453258L6.39265 6.2752C6.46365 6.35981 6.45261 6.48596 6.368 6.55696L5.29554 7.45686C5.21092 7.52786 5.08477 7.51683 5.01377 7.43221L0.128584 1.61028C0.0575832 1.52566 0.0686202 1.39951 0.153235 1.32851L1.2257 0.428606Z"
          fill="#F32D5F"
        />
        <path
          d="M0.153209 11.5286C0.068594 11.4576 0.0575572 11.3314 0.128558 11.2468L5.01374 5.42488C5.08474 5.34026 5.21089 5.32923 5.29551 5.40023L6.36797 6.30013C6.45259 6.37113 6.46362 6.49728 6.39262 6.5819L1.50744 12.4038C1.43644 12.4884 1.31029 12.4995 1.22567 12.4285L0.153209 11.5286Z"
          fill="#F32D5F"
        />
      </svg>
    </Box>
  );
};
