import React from "react";
import { Heading } from "@chakra-ui/react";

const ItemHeading = (props) => {
  return (
    <Heading
      as="h3"
      fontFamily="Montserrat, sans-serif"
      fontSize={["lg", "xl", "1.375rem"]}
      fontWeight={[600, 600, 500]}
      lineHeight="shorter"
      textColor="brand.100"
      maxW="full"
      w="full"
      {...props}
    >
      {props.children}
    </Heading>
  );
};

export default ItemHeading;
