import React from "react";
import { Link, Button } from "@chakra-ui/react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link as ReachLink } from "gatsby";

const ButtonPrimary = ({ children, linkType, linkUrl }) => {
  return (
    <>
      {linkType === "Anchor" ? (
        <Link
          as={AnchorLink}
          href={linkUrl}
          d="flex"
          alignItems="center"
          textColor="brand.400"
          fontWeight="600"
          _hover={{ textDecoration: "none" }}
          w={["full", "11.25rem"]}
          _focus={{ outline: "none" }}
        >
          <Button
            bgColor="brand.400"
            borderRadius="0.875rem"
            w={["full", "11.25rem"]}
            h={[10, "2.75rem"]}
            color="brand.700"
            fontSize={["md", "lg"]}
            fontWeight={[600]}
            _hover={{ bgColor: "brand.450" }}
            _active={{ bgColor: "brand.450" }}
          >
            {children}
          </Button>
        </Link>
      ) : linkType === "Internal" ? (
        <Link
          as={ReachLink}
          to={linkUrl}
          d="flex"
          alignItems="center"
          textColor="brand.400"
          fontWeight="600"
          _hover={{ textDecoration: "none" }}
          w={["full", "11.25rem"]}
          _focus={{ outline: "none" }}
        >
          <Button
            bgColor="brand.400"
            borderRadius="0.875rem"
            w={["full", "11.25rem"]}
            h={[10, "2.75rem"]}
            color="brand.700"
            fontSize={["md", "lg"]}
            fontWeight={[600]}
            _hover={{ bgColor: "brand.450" }}
            _active={{ bgColor: "brand.450" }}
          >
            {children}
          </Button>
        </Link>
      ) : (
        <Link
          href={linkUrl}
          isExternal
          d="flex"
          alignItems="center"
          textColor="brand.400"
          fontWeight="600"
          _hover={{ textDecoration: "none" }}
          w={["full", "11.25rem"]}
          _focus={{ outline: "none" }}
        >
          <Button
            bgColor="brand.400"
            borderRadius="0.875rem"
            w={["full", "11.25rem"]}
            h={[10, "2.75rem"]}
            color="brand.700"
            fontSize={["md", "lg"]}
            fontWeight={[600]}
            _hover={{ bgColor: "brand.450" }}
            _active={{ bgColor: "brand.450" }}
          >
            {children}
          </Button>
        </Link>
      )}
    </>
  );
};

export default ButtonPrimary;
