import React from "react";
import { Heading } from "@chakra-ui/react";

const ModulesHeading = (props) => {
  return (
    <Heading
      as="h3"
      fontFamily="Montserrat, sans-serif"
      fontSize={["lg", "xl", "1.375rem"]}
      fontWeight={[600]}
      lineHeight="shorter"
      textColor="brand.300"
      maxW="full"
      w="full"
      {...props}
      pt={[8, 10]}
      pb={[3, 4]}
    >
      {props.children}
    </Heading>
  );
};

export default ModulesHeading;
